const messages = {
  tr: {
    cancel: 'İptal',
    approve: 'Onaylıyorum',
    send: 'Gönder',
    sending: 'Gönderiliyor...',
    name: 'Ad Soyad',
    email: 'Email',
    last_name: 'Soyad',
    name_last_name: 'Ad Soyad',
    please_wait: 'Lütfen Bekleyin',
    registration: 'Giriş',
    city: 'Şehir',
    'country/region': 'Ülke',
    'zip/postal_code': 'Posta Kodu',
    'state/province': 'Bölge',
    phone: 'Telefon Numarası',
    address: 'Adres',
    speciality: 'Uzmanlık',
    organization: 'Kurum',
    job_title: 'Title',
    kvkk_onay: '<b>Aydınlatma Metnini</b> okudum, onaylıyorum',
    event_password: 'Etkinlik Şifresi',
    no_event_found: 'Etkinlik bulunamadı',
    okey: 'Tamam',
    join_now: 'Hemen Katılın',
    page_language: 'Sayfa dilini değiştirin',
    type_your_question: 'Sorunuzu bu alana yazabilirsiniz.',
    this_event_is_not_started: 'Etkinlik henüz başlamadı.',
    read_kvkk_to_end: 'Aydınlatma Metni\'ni sonuna kadar okuyun.',
    kvkk_header: 'KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AYDINLATMA METNİ',
    registration_required:
      'Bu event için kayıt olmak zorunludur. Kayıt sayfasına yönlendiriliyorsunuz',
  },
};

export default messages;
